import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accordion",
      "style": {
        "position": "relative"
      }
    }}>{`Accordion`}</h1>
    <p>{`An accordion is a clickable component that reveals or hide content associated with it. Allowing people to have control over the content by expanding it or deferring it for later lets them decide what to read and what to ignore.
Collapsing the page minimizes scrolling, and the headings serve as a mini-IA of the page, this allows users to form a mental model of the information available.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Accordions should be avoided when your audience needs most or all of the content on the page to answer their questions. Better to show all page content at once when the use case supports it.`}</li>
      <li parentName="ul">{`Make sure to optimize your pages for printing. Accordions are often not well suited for printing documents and require people to print snippets of content at a time.`}</li>
      <li parentName="ul">{`In tables, in situations where the list has only some expandable items, the items that do not use the accordion should have the same indentation as the others. When applied to cards, it is not necessary to maintain text alignment.`}</li>
    </ul>
    <p><strong parentName="p">{`This component hasn't yet been implemented as a react component.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      